<template>
  <CContainer class="d-flex align-items-center min-vh-100">
    <CRow class="w-100 justify-content-center">
      <CCol md="6">
        <div class="w-100">
          <div class="clearfix">
            <h1 class="float-left display-3 mr-4">403</h1>
            <h4 class="pt-3">Oops!.</h4>
            <p class="text-muted">
              You do not have permission to access this page
            </p>
          </div>

          <CButton color="info" @click="gotoDashboard()">Back</CButton>
        </div>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import store from "../../store/store";
import {Mixin} from "/src/mixins/index.js";

export default {
  name: "Page403",
  mixins: [Mixin],
  mounted() {
    store.commit("showLoader", false); // Loader Start
  },
  methods: {
    gotoDashboard() {
      let self = this;
      self.$router.push({path: "/dashboard"});
    },
  },
};
</script>
